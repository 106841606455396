import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, Link } from 'react-router-dom';
import '../App.css';
import { connect } from 'react-redux';
import BASE_URL from '../baseUrl';

function Home(props) {
  const { id } = useParams();
  const [client, setClient] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(`${BASE_URL}/clients/${id}`);
      const { data } = await res.json();
      setClient(data);
    };
    fetchData();
  }, [id]);

  if (!client) {
    return (
      <div className="loading">
        <h1>Chargement...</h1>
      </div>
    );
  }

  return (
    <div style={{ borderColor: client.color }} className="container">
      <div className="content_container">
        <img className="logo_home" src={client.home_logo} alt={`logo ${client.title}`} />
        <Link onClick={() => props.onAddClient(client)} to={`/${id}/select_category`}>
          <button type="button" style={{ backgroundColor: client.color }} className="button_home">
            <p className="buttonText_home">Découvrir</p>
          </button>
        </Link>
      </div>
    </div>
  );
}

Home.propTypes = {
  onAddClient: PropTypes.string.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    onAddClient: (client) => {
      dispatch({ type: 'add_client', client });
    },
  };
}

export default connect(null, mapDispatchToProps)(Home);
