import React from 'react';
import { Redirect, useParams } from 'react-router-dom';

function Redirection() {
  const { id } = useParams();

  return <Redirect to={`/${id}/home`} />;
}

export default Redirection;
