import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, Link, Redirect } from 'react-router-dom';
import BASE_URL from '../baseUrl';

function CatagorySelector({ clientToDisplay }) {
  const { id } = useParams();
  const [categories, setCategories] = useState([]);
  const client = clientToDisplay;

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(`${BASE_URL}/categories`);
      const { data } = await res.json();
      setCategories(data);
    };
    fetchData();
  }, [id]);

  if (!clientToDisplay) {
    return <Redirect to={`/${id}`} />;
  }

  if (!categories) {
    return (
      <div style={{ borderColor: client.color }} className="loading_category">
        <img className="logo_category" src={client.logo} alt="logo" />
        <h1 className="loading_text">Chargement...</h1>
      </div>
    );
  }

  return (
    <div style={{ borderColor: client.color }} className="categorySelector_container">
      <div className="header">
        <Link to={`/${id}/home`}>
          <img src={client.logo} className="logo_category" alt="logo" />
        </Link>

        <h1 className="choose_title">Choississez le thème de votre événement !</h1>
      </div>
      <div className="row">
        {categories.map((category) => {
          return (
            <Link key={category.id} to={`/${id}/${category.slug}`}>
              <div style={{ borderColor: client.color }} className="category">
                <img className="image_category" src={category.thumb} alt="image_category" />
                <p className="title_category">{category.name}</p>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}
CatagorySelector.propTypes = {
  clientToDisplay: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return { clientToDisplay: state.client };
}
export default connect(mapStateToProps, null)(CatagorySelector);
