import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import Home from './component/Home';
import CategorySelector from './component/CategorySelector';
import CategoryView from './component/CategoryView';
import Error from './component/Error';
import Redirection from './component/Redirection';
import client from './reducers/client';

const store = createStore(combineReducers({ client }));

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/" exact component={Error} />
          <Route path="/:id" exact component={Redirection} />
          <Route path="/:id/home" exact component={Home} />
          <Route path="/:id/select_category" exact component={CategorySelector} />
          <Route path="/:id/:categoryName" exact component={CategoryView} />
        </Switch>
      </Router>
    </Provider>
  );
}
export default App;
