import React from 'react';

function Error() {
  return (
    <div className="error_container">
      <h1>Erreur 404</h1>
      <h1>Cette page n&apos;existe pas</h1>
    </div>
  );
}

export default Error;
