import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, Link, Redirect, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import '../App.css';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloseIcon from '@material-ui/icons/Close';
import { SimpleImg } from 'react-simple-img';
import Select from 'react-select';
import BASE_URL from '../baseUrl';
import useWindowSize from '../hooks/useWindowSize';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function CategoryView({ clientToDisplay }) {
  const history = useHistory();
  const { width } = useWindowSize();
  const { categoryName, id } = useParams(null);
  const [categories, setCategories] = useState([]);
  const [collections, setCollections] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = useState(null);
  const [imgIndex, setImgIndex] = useState(0);
  const [images, setImages] = useState([]);
  const client = clientToDisplay;

  const isSmall = 768 > width;

  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const handleOpen = (url, uri, imgId, collectionId) => {
    const selectedList = collections.find((collection) => collection.id === collectionId).themes;
    setImages(selectedList);
    const indexToOpen = selectedList.findIndex((item) => item.id === imgId);
    setImgIndex(indexToOpen);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(`${BASE_URL}/categories`);
      const { data } = await res.json();
      setCategories(data);

      const resCategory = await fetch(`${BASE_URL}/categories/${categoryName}`);
      const { data: dataCategory } = await resCategory.json();
      setCollections(dataCategory.collections);

      const selectedCategory = data.find((i) => i.slug === categoryName);
      if (selectedCategory) {
        setSelected({ value: selectedCategory.id, label: selectedCategory.name });
      }
    };
    fetchData();
  }, [id, categoryName]);

  const urlToDownload = images[imgIndex]?.downloadable_file;

  const handleChangeCategory = () => {
    setCollections([]);
  };

  const sortedTab = (themes) => {
    function compare(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    }
    themes.sort(compare);
    return themes;
  };
  let collectionsToDisplay = <></>;
  if (0 < collections.length) {
    collectionsToDisplay = collections.map((collection) => {
      return (
        <div key={collection.id} className="collection_row">
          {sortedTab(collection.themes).map((theme, index) => {
            if (isSmall && 0 !== index) {
              return null;
            }
            return (
              <div aria-hidden role="button" onClick={() => handleOpen(theme.picture, theme.downloadable_file, theme.id, collection.id)} className="image_container" key={theme.id}>
                <SimpleImg className="image_theme" height={isSmall ? 150 : 200} src={theme.thumb} placeholder="#F8F8F8" alt="theme" />
                <p>{theme.name}</p>
              </div>
            );
          })}
        </div>
      );
    });
  } else {
    collectionsToDisplay = <div>Chargement...</div>;
  }

  const options = categories.map((category) => ({ value: category.id, label: category.name })) ?? [];

  if (!clientToDisplay) {
    return <Redirect to={`/${id}`} />;
  }

  if (!categories) {
    return (
      <div style={{ borderColor: client.color }} className="loading_category">
        <img className="logo_category" src={client.logo} alt="logo" />
        <h1 className="loading_text">Chargement...</h1>
      </div>
    );
  }

  const handleSelect = (value) => {
    setSelected(value);
    const selectedItem = categories.find((item) => item.id === value.value);
    if (selectedItem) {
      history.push(`/${id}/${selectedItem.slug}`);
      handleChangeCategory();
    }
  };

  return (
    <div style={{ borderColor: client.color }} className="categorySelector_container">
      <div className="header">
        <Link to={`/${id}/select_category`}>
          <img alt="logo" src={client.logo} className="logo_category" />
        </Link>
        <div style={{ width: '80%', maxWidth: '400px', marginBottom: '10px' }}>
          <Select options={options} onChange={handleSelect} value={selected} />
        </div>
      </div>

      <div className="collections_container">{collectionsToDisplay}</div>

      <Modal open={open} onClose={handleClose} aria-labelledby="simple-modal-name" aria-describedby="simple-modal-description">
        <div style={modalStyle} className={`${classes.paper} modal`}>
          <div style={{ width: '100%', marginTop: '20px', display: 'flex', flexDirection: 'column' }}>
            <button
              type="button"
              onClick={handleClose}
              style={{ position: 'absolute', top: 0, right: 0, border: 'none', alignSelf: 'end', backgroundColor: 'transparent', width: '50px', height: '40px', cursor: 'pointer' }}
            >
              <CloseIcon style={{ color: 'black' }} />
            </button>

            <div style={{ position: 'relative' }}>
              <button
                type="button"
                onClick={() => {
                  if (0 < imgIndex) {
                    setImgIndex(imgIndex - 1);
                  }
                }}
                disabled={0 === imgIndex}
                className="carousel_button"
                style={{
                  cursor: 0 === imgIndex ? 'default' : 'pointer',
                }}
              >
                Précédent
              </button>
              <button
                type="button"
                onClick={() => {
                  if (imgIndex < images.length - 1) {
                    setImgIndex(imgIndex + 1);
                  }
                }}
                disabled={imgIndex === images.length - 1}
                className="carousel_button"
                style={{
                  right: 0,
                  cursor: imgIndex === images.length - 1 ? 'default' : 'pointer',
                }}
              >
                Suivant
              </button>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
              <img className="image_modal" src={images[imgIndex]?.picture} alt="src" style={{ marginTop: '50px', objectFit: 'contain', width: '100%' }} />
              <p style={{ marginTop: '10px' }}>{images[imgIndex]?.name}</p>
            </div>
          </div>
          {client.can_download ? (
            <button type="button" onClick={() => window.open(urlToDownload)} style={{ backgroundColor: client.color }} className="button_download">
              <p className="buttonText_home">Télécharcher le PSD</p>
              <CloudDownloadIcon />{' '}
            </button>
          ) : null}
        </div>
      </Modal>
    </div>
  );
}

CategoryView.propTypes = {
  clientToDisplay: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return { clientToDisplay: state.client };
}
export default connect(mapStateToProps, null)(CategoryView);
